import React, { Component } from "react";
import UpdateTime from "./UpdateTime";

export class ModuleHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { weatherConditions, title } = this.props;
    return (
      <div className="module__header">
        {title}
        {weatherConditions?<UpdateTime text="" localTime={weatherConditions.obsTimeLocal}/> : ""} 
      </div>
    );
  }
}
export default ModuleHeader;
