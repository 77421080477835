import React, { Component } from "react";
export class UpdateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: "",
      lapseTime: "...",
      stationTime: "",
    };
    this.interval;
    this.convertMiliseconds = this.convertMiliseconds.bind(this);
    this.fixDateForAllBrowsers = this.fixDateForAllBrowsers.bind(this);
    this.calculateTime = this.calculateTime.bind(this);
  }
  componentDidMount() {
    var stationTime = new Date(this.fixDateForAllBrowsers(this.props.localTime));
    this.setState({ stationTime }, () => {
      this.interval = setInterval(this.calculateTime, 1000);
    });
  }
  componentWillUnmount() {
    console.log("got unmounted");
    clearInterval(this.interval);
  }
  fixDateForAllBrowsers(dateString) {
    return dateString.replace(/-/g, "/");
  }

  calculateTime() {
    // const fixedTimeString = this.fixDateForAllBrowsers(time);
    const stationTime = this.state.stationTime;
    const currentTime = new Date();
    const elapsed = currentTime - stationTime;
    const conversion = this.convertMiliseconds(elapsed, "all", this.renderTime);
    // // console.log("conversion: ", conversion);
    this.setState({ lapseTime: conversion });
    // return conversion;
    // return interval;

    // setInterval(internal
    //   // // console.log("conversion2: ", internal());
    //   return internal();
    // }, 1000);
    // }
    // // console.log("conversion3: ", interval());
  }
  renderTime(conversion) {
    // // console.log("conversion1:", conversion);
    if (conversion.d > 1) {
      // console.log("day");
      return `${conversion.d} days`;
    }
    if (conversion.d > 0) {
      // console.log("day");
      return `${conversion.d} day`;
    }
    if (conversion.h > 1) {
      return `${conversion.h} hours`;
    }
    if (conversion.h > 0) {
      // console.log("hour");
      return `${conversion.h} hour`;
    }
    if (conversion.m > 1) {
      // console.log("min");
      return `${conversion.m} minutes`;
    }
    if (conversion.m > 0) {
      // console.log("min");
      return `${conversion.m} minute`;
    }
    if (conversion.s > 1) {
      // console.log("sec");
      return `${conversion.s} seconds`;
    }
    if (conversion.s === 1) {
      // console.log("sec");
      return `${conversion.s} second`;
    } else {
      return "unknown";
    }
  }
  convertMiliseconds(miliseconds, format = "all", callback) {
    var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;

    total_seconds = parseInt(Math.floor(miliseconds / 1000));
    total_minutes = parseInt(Math.floor(total_seconds / 60));
    total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));

    seconds = parseInt(total_seconds % 60);
    minutes = parseInt(total_minutes % 60);
    hours = parseInt(total_hours % 24);

    switch (format) {
      case "s":
        return callback(total_seconds);
      case "m":
        return callback(total_minutes);
      case "h":
        return callback(total_hours);
      case "d":
        return callback(days);
      default:
        return callback({ d: days, h: hours, m: minutes, s: seconds });
    }
  }
  render() {
    const { text, localTime, UTCTime } = this.props;
    return <span className="update_time">{`${text} ${this.state.lapseTime} ago`}</span>;
  }
}
export default UpdateTime;
