import React from 'react';

function Information() {
  return (
    <div className="content__wrapper">
      <h2>Information</h2>
      <p>None at this time.</p>
      <h2>Events</h2>
      <p>No events scheduled at this time.</p>
    </div>
  );
}

export default Information;