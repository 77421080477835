import React, { Component } from "react";
const { useEffect, useState } = React;
import DialGuage from "./DialGuage";
import TextGuage from "./TextGuage";
import RainGuage from "./RainGuage";
import WeatherMap from "./WeatherMap";
import Shimmer from "../../utils/Shimmer";
function WeatherWrapper (props) {
 

  const calculateTempRating = (temp = 50) => {
    if (temp > 80) {
      return "hot";
    }
    if (temp < 80 && temp > 65) {
      return "warm";
    }
    if (temp < 65 && temp > 40) {
      return "comfortable";
    }
    if (temp < 40) {
      return "cold";
    }
  }
  
    const { weatherIsLoaded, weatherConditions, appStatus } = props;
    if (weatherIsLoaded === undefined) {
      return <p>Unable to load weather data</p>;
    }
    return (
      <div className="dashboard__container">
        {!weatherIsLoaded ? (
          <Shimmer rows={5} />
        ) : (
          <DialGuage
            {...props}
            title="WIND"
            img1={{
              url: "https://s.w-x.co/wu/assets/static/images/pws/Wind-Dial.svg",
              class: "wind-dial",
            }}
            img2={{
              url: "https://s.w-x.co/wu/assets/static/images/pws/Wind-Marker.svg",
              class: "",
            }}
            containerClass="wind-circle-container"
            containerSubClass="wind-dial__container"
            metric1={`${Math.max(
              Math.round(weatherConditions.imperial.windSpeed) / 1.151
            ).toFixed(0)}`}
            metric1Subheading="KTS"
            metric2={weatherConditions.winddir}
            metric3={`${Math.max(
              Math.round(weatherConditions.imperial.windGust) / 1.151
            ).toFixed(0)} kts`}
            weatherHeader1="WIND FROM"
            weatherHeader2="GUST"
            rotationStyle={`translateX(-50%) rotate(${
              weatherConditions.winddir - 180
            }deg)`}
            pointerWrapperClass="arrow-wrapper"
            moduleClass=""
            weatherConditions={weatherConditions}
            weatherIsLoaded={weatherIsLoaded}
          />
        )}
        {!weatherIsLoaded ? (
          <Shimmer rows={5} />
          ) : (
          <DialGuage
            {...props}
            title="PRESSURE"
            img1={{
              url: "https://s.w-x.co/wu/assets/static/images/pws/Pressure-Dial.svg",
              class: "pressure-dial",
            }}
            img2={{
              url: "https://s.w-x.co/wu/assets/static/images/pws/Wind-Marker.svg",
              class: "pressure-marker",
            }}
            containerClass="pressure-circle-container"
            containerSubClass="pressure-dial__container"
            metric1=""
            metric2={weatherConditions.imperial.pressure}
            metric3=""
            weatherHeader1="CURRENT"
            weatherHeader2=""
            tempRatingClass=""
            rotationStyle={`translateX(0) rotate(${
              (weatherConditions.imperial.pressure - 28.25) * 28 * 3.77 + 48
            }deg)`}
            pointerWrapperClass="marker-wrapper"
            moduleClass=""
            weatherConditions={weatherConditions}
            weatherIsLoaded={weatherIsLoaded}
          />
        )}
        {!weatherIsLoaded ? (
          <Shimmer rows={1} />
          ) : (
          <DialGuage
            {...props}
            title="TEMPERATURE"
            img1={{
              url: "",
              class: "temperature-dial",
            }}
            img2={{ url: "", class: "" }}
            containerClass="temperature-circle-container"
            containerSubClass="temperature-dial__container"
            metric1Subheading="CURRENT"
            metric1={weatherConditions.imperial.temp}
            metric2={weatherConditions.imperial.dewpt}
            metric3={weatherConditions.humidity}
            weatherHeader1="DEWPOINT"
            weatherHeader2="HUMIDITY"
            tempRatingClass={calculateTempRating(
              weatherConditions.imperial.temp
            )}
            rotationStyle={""}
            pointerWrapperClass="marker-wrapper"
            moduleClass=""
            weatherConditions={weatherConditions}
            weatherIsLoaded={weatherIsLoaded}
          />
        )}
        {!weatherIsLoaded ? (
          <Shimmer rows={10} moduleClass="rain-guage" />
          ) : (
          <RainGuage
            {...props}
            weatherConditions={weatherConditions}
            title="PRECIPITATION"
            moduleClass="rain-guage"
            metric1={weatherConditions.imperial.precipRate}
            weatherHeader1="RATE"
            weatherIsLoaded={weatherIsLoaded}
          />
        )}
        {!weatherIsLoaded ? (
          <Shimmer rows={20} moduleClass="weather-map"/>
          ) : (
          <WeatherMap
            moduleClass="weather-map"
            imgData={{ url: "", title: "Weather Map" }}
            weatherIsLoaded={weatherIsLoaded}
          />
        )}
        {!weatherIsLoaded ? (
          <Shimmer rows={5} />
          ) : (
          <TextGuage
            {...props}
            weatherConditions={weatherConditions}
            title="STATION DETAILS"
            weatherIsLoaded={weatherIsLoaded}
          />
        )}
      </div>
    );

    // if (weatherStatus == "LOADING") {
    //   return (<div className="loading">
    //   <img src="weather/src/assets/img/prop-loading.gif" />
    // </div>);
    // }
  }
export default WeatherWrapper;
