import React from "react";

function Operations() {
  return (
    <div className="content__wrapper">
      <h1>Operations</h1>
      <div>
        <p className="intro">
          Burnett Landing (WN15) is a private airstrip. However, if you
          know someone on the airport and want to drop in and say hello, we
          welcome it.
        </p>
        <h2>General Information</h2>
        <ul>
          <li>Field Elevation: 792ft MSL</li>
          <li>Turf 13/31 = 2500' x 110'</li>
          <li>Left traffic.</li>
          <li>TPA: 1800 MSL.</li>
          <li>CTAF: 122.90</li>
          <li>Numerous non-radio aircraft.</li>
          <li>Use caution for runway mowing activity.</li>
          <li>Avoid full power climb outs on runway heading for runway 13. Make left turn by end of runway to avoid noise directly above homes to the south of the airport.</li>
          <li>Burnett Landing is an active hang-gliding airport. Please exercise caution when in or around the airport.</li>
          <li>NO Touch & Gos</li>
          <li>You should be stopped before you reach the road at the south end of the runway. Be aware of cars crossing runway on road.</li>
        </ul>
        
        <h2>Runway Conditions</h2>
       
        <p>Please avoid any areas of the runway that are under repair.</p>
        
        <h2>Safety Tips</h2>
        <p>
          Elk, deer and people can often be found enjoying the runway. Please
          keep a watchful eye out.
        </p>
      </div>
    </div>
  );
}

export default Operations;
