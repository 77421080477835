import React from 'react';
import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './components/App'; // Import your root component

// Use createRoot from react-dom/client as needed
const root = createRoot(document.getElementById('root'));

// Render your root component into the DOM
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);