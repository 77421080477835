import React, { Component } from "react";
import ModuleHeader from "./ModuleHeader";
export class RainGuage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { moduleClass, title, weatherConditions, weatherHeader1, metric1 } = this.props;
    const bgStyle = {
      height: `${weatherConditions.imperial.precipTotal * 72}px`,
    };
    const wrapperStyle = {
      height: "400px",
    };

    return (
      <div className={`module__container ${moduleClass}`} style={wrapperStyle}>
        <ModuleHeader title={title} weatherConditions={weatherConditions}/>
        <div className="module__body">
          <div className="small-5 columns text-center large-offset-1">
            <div className="rain-wrap">
              <div className="rain-tube">
                <div className="bg">
                  <div className="rain" style={bgStyle} />
                </div>
              </div>
              <div className="wrap">
                <span className="device-formatted-data-point fdp monthlyrainin">
                  <span className="fdp-val">{weatherConditions.imperial.precipTotal}</span>
                  <span className="suff"> inches</span>
                </span>
                <div className="label">today</div>
              </div>
            </div>
          </div>
          <div className="small-3 columns large-4">
            <div className="weather__data">
              <div className="weather__header">{weatherHeader1}</div>
              <div className="weather__text">{`${metric1} / hour` }</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RainGuage;
