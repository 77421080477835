import React, { Component } from "react";
import ModuleHeader from "./ModuleHeader";
export class WeatherMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerHeight: "",
      containerWidth: "",
    };
  }
  componentDidMount() {
    const containerHeight = (document.getElementById("map").clientHeight) -60;
    const containerWidth = (document.getElementById("map").clientWidth)-10;
    this.setState({ containerHeight, containerWidth });
  }
  render() {
    const { moduleClass, imgData } = this.props;
    return (
      <div className={`module__container ${moduleClass}`} id="map">
        <ModuleHeader title={imgData.title} />
        <div className="module__body">
          <div className="">
            <iframe
              width={this.state.containerWidth}
              height={this.state.containerHeight}
              src="https://embed.windy.com/embed2.html?lat=47.398&lon=-121.377&detailLat=47.231&detailLon=-119.399&width=650&height=450&zoom=6&level=surface&overlay=radar&product=radar&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=kt&metricTemp=%C2%B0F&radarRange=-1"
              frameBorder="0"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default WeatherMap;
