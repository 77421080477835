import React, { Component } from "react";
import ModuleHeader from "./ModuleHeader";
export class TextGuage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { weatherConditions, title } = this.props;
    return (
      <div className="module__container">
        <ModuleHeader title={title} weatherConditions={weatherConditions} />
        <div className="module__body">
          <div className="text_guage">
            <ul>
              <li className="weather__header">Last Updated (Local Time): <br /><span className="weather__text"> {weatherConditions.obsTimeLocal}</span></li>
              <li className="weather__header">Last Updated (UTC): <br /><span className="weather__text">{weatherConditions.obsTimeUtc}</span></li>
              <li className="weather__header">Station Location: <br /><span className="weather__text"> {`${weatherConditions.lat} / ${weatherConditions.lon}` }</span></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default TextGuage;
