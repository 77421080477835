import React, { Component } from "react";
const { useEffect, useState } = React;
import WeatherWrapper from "./WeatherWrapper";
import WebCam from "./WebCam";
// import styles from "../../dist/weather-app.css";
import Controls from "./Controls";
import appRequest, { getConditions } from "../../utils/appRequest";
import Shimmer from "../../utils/Shimmer";

function Weather(props) {
  const [appStatus, setAppStatus] = useState("LOADING");

  const [weatherConditions, setWeatherConditions] = useState({});
  const [autoUpdate, setAutoUpdate] = useState(true);
  const [autoUpdateIntervalConstant, setAutoUpdateIntervalConstant] =
    useState(300000);
  const [weatherIsLoaded, setWeatherIsLoaded] = useState(false);
  

  const stateData = {
    appStatus,
    weatherConditions,
    autoUpdate,
    autoUpdateIntervalConstant,
    weatherIsLoaded,
  };
  let autoUpdateInterval;
  // this.state = {
  //   status: "LOADING",
  //   darkMode: false,
  //   weatherConditions: {},
  //   weatherStatus: "LOADING",
  //   autoUpdate: true,
  //   autoUpdateIntervalConstant: 300000,
  // };

  // this.toggleDarkMode = this.toggleDarkMode.bind(this);
  // this.getConditions = this.getConditions.bind(this);


 

  const getConditions = () => {
    setWeatherConditions({});
    setWeatherIsLoaded(false);
    appRequest
      .getConditions()
      .then((resp) => {
        setWeatherConditions(resp);
        setWeatherIsLoaded(true);
      })
      .catch(() => {
        setWeatherIsLoaded(undefined);
      });
  };

  const autoUpdateMethod = (prevState) => {
    setAutoUpdate((current) => !current);
    if (prevState === false) {
      getConditions();
      autoUpdateInterval = setInterval(
        getConditions,
        autoUpdateIntervalConstant
      );
    } else {
      clearInterval(autoUpdateInterval);
    }
  };

  useEffect(() => {
    if (appStatus != "LOADED") {
      getConditions();
      setAppStatus("LOADED");
    }
    
    if (autoUpdate) {
      autoUpdateInterval = setInterval(
        getConditions,
        autoUpdateIntervalConstant
      );
    }
  }, []);

  // const { status, darkMode } = state;
  // let displayMode = "light";
  // props.darkMode ? (displayMode = "dark") : (displayMode = "light");
  const currentTime = performance.now();
  if (appStatus === "ERROR") {
    return <p>Unable to load weather data</p>;
  }
  return (
    <React.Fragment>
      <section id="weather-root">
        <div className={`controls ${props.displayMode} content__wrapper`}>
          <Controls
            title="AUTO REFRESH"
            controlClassName="wxManualOverride"
            clickFunction={autoUpdateMethod}
            checked={autoUpdate}
          />

          <Controls
            title="NIGHT MODE"
            controlClassName="nightShiftBtn"
            clickFunction={props.toggleDarkMode}
            checked={props.darkMode}
          />
        </div>
        <div className={`weather-cameras ${props.displayMode}`}>
          <div className="dashboard__container">
            {/* {isImageLoading ? (
              <Shimmer rows={10} moduleClass="webcam" />
              ) : isImageLoaded ? ( */}
              <WebCam
                moduleClass="webcam"
                imgData={{
                  url: `/common/img/webcams/WN15_north.jpg?cache=false&epoch=${currentTime}`,
                  title: "North Camera",
                }}
                componentStatus={appStatus}
                
              />
            {/* ):(<div className="module__container webcam">Unable to load image.</div>)} */}
            {/* {isImageLoading ? (
              <Shimmer rows={10} moduleClass="webcam" />
              ) : isImageLoaded ? ( */}
              <WebCam
                moduleClass="webcam"
                imgData={{
                  url: `/common/img/webcams/WN15_southeast.jpg?cache=false&epoch=${currentTime}`,
                  title: "Southeast Camera",
                }}
                componentStatus={appStatus}
                
              />
              {/* ):(<div className="module__container webcam">Unable to load image.</div>)} */}
            {/* {isImageLoading ? (
              <Shimmer rows={10} moduleClass="webcam" />
              ) : isImageLoaded ? ( */}
              <WebCam
                moduleClass="webcam"
                imgData={{
                  url: `/common/img/webcams/WN15_south.jpg?cache=false&epoch=${currentTime}`,
                  title: "South Camera",
                }}
                componentStatus={appStatus}
                
              />
              {/* ):(<div className="module__container webcam">Unable to load image.</div>)} */}
          </div>
        </div>
        <div className={`weather-data ${props.displayMode}`}>
          <WeatherWrapper
            {...stateData}
            getConditions={getConditions}
            componentStatus={appStatus}
          />
        </div>
      </section>
    </React.Fragment>
  );
}
export default Weather;
