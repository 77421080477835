import React, { Component } from "react";
import ModuleHeader from "./ModuleHeader";
import Shimmer from "../../utils/Shimmer"
function DialGuage (props) {
  

    const {
      tempRatingClass,
      metric1Subheading,
      pointerWrapperClass,
      rotationStyle,
      title,
      img1,
      img2,
      containerClass,
      containerSubClass,
      metric1,
      metric2,
      metric3,
      weatherHeader1,
      weatherHeader2,
      moduleClass,
      weatherConditions,
      weatherIsLoaded
    } = props;
    console.log(rotationStyle);
    const dialStyle = {
      transform: rotationStyle,
    };
    if(!weatherIsLoaded){
      return <Shimmer />
    }
    return (
      <div className={`module__container ${moduleClass}`}>
        <ModuleHeader title={title} weatherConditions={weatherConditions} />
        <div className="module__body">
          <div className="small-5 columns text-center large-offset-1">
            <div className={containerClass}>
              <div className={`${containerSubClass} ${tempRatingClass}`}>
                {img1.url != "" ? <img alt="img" src={img1.url} className={img1.class} /> : ""}

                <div className="text-wrapper">
                  <span className="big">{metric1}</span>
                  <span className="small">{metric1Subheading}</span>
                </div>
                <div className={pointerWrapperClass} style={dialStyle}>
                  {img2.url != "" ? <img alt="img" src={img2.url} className={img2.class} /> : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="small-3 columns large-4">
            <div className="weather__data">
              <div className="weather__header">{weatherHeader1}</div>
              <div className="weather__text">{metric2}</div>
              <div className="weather__header">{weatherHeader2}</div>
              <div className="weather__text">{metric3}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default DialGuage;
