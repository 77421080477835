import React from 'react';

function Shimmer({ rows, moduleClass="" }) {
//   const shimmerContainerStyle = {
//     width,
//     height: `${height * rows}px`, // Set the container height based on rows
//   };

  // const shimmerLines = [];
  // for (let i = 0; i < rows; i++) {
  //   shimmerLines.push(
  //     <div className="shimmer-line" key={i}></div>
  //   );
  // }

  return (
    <div className={`shimmer module__container ${moduleClass}`}>
      <div className="p-32">
          <div className="shimmerBG title-line"></div>
          <div className="shimmerBG title-line end"></div>

          <div className="shimmerBG content-line m-t-24"></div>
          <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line end"></div>
          </div>
    </div>
  );
}

export default Shimmer;
