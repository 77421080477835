import React from "react";
const { useEffect, useState } = React;
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Switch,
  NavLink,
} from "react-router-dom";
import Weather from "./Weather/Weather";
import Operations from "./Operations";
import Information from "./Information";

import "../assets/less/main.less"; // Import the Less file

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode((current) => !current);
    const wrapper = document.querySelectorAll(".wrapper");
    darkMode
      ? wrapper[0].classList.add("dark")
      : wrapper[0].classList.remove("dark");
  };
  let displayMode = "light";
  darkMode ? (displayMode = "dark") : (displayMode = "light");
  return (
    <Router>
      <div className={`wrapper ${displayMode}`}>
        <header>
        <video controls={false} autoPlay={true} muted loop playsInline>
          {/* <source src="./video/WN15_540p.mp4" type="video/mp4"/> */}
          <source src="./public/videos/WN15_540p.mp4" type="video/mp4"/>
          </video>
          <h1 className="site_title">
            <NavLink to="/">Burnett Landing Airport</NavLink>
          </h1>
          <nav>
            <p>
              <NavLink
                to="/"
                className={(isActive) =>
                  "nav-link" + (isActive ? " loaded" : "")
                }
              >
                Weather
              </NavLink>
            </p>
            <p>
              <NavLink
                to="/operations"
                className={(isActive) =>
                  "nav-link" + (isActive ? " loaded" : "")
                }
              >
                Operations &amp; Visiting
              </NavLink>
            </p>
            <p>
              <NavLink
                to="/information"
                className={(isActive) =>
                  "nav-link" + (isActive ? " loaded" : "")
                }
              >
                Information
              </NavLink>
            </p>
          </nav>
        </header>
        <Routes>
          <Route
            path="/"
            element={
              <Weather
                toggleDarkMode={toggleDarkMode}
                displayMode={displayMode}
              />
            }
          />
          <Route path="/operations" element={<Operations />} />
          <Route path="/information" element={<Information />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
