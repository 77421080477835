import React, { Component } from "react";
const { useEffect, useState } = React;
import ModuleHeader from "./ModuleHeader";
// import Shimmer from "../../utils/Shimmer";
import { Image, Breathing, Shimmer } from "react-shimmer";
function WebCam(props) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    // handleImageLoad();
    // const timeoutId = setTimeout(() => {
    //   setIsImageLoading(false);
    // }, 5000);

    // return () => {
    //   clearTimeout(timeoutId); // Clear the timeout when the component unmounts
    // };
  });

  // const handleImageLoad = () => {
  //   // This function is called when the image has finished loading
  //   return (
  //     <img
  //       src={imgData.url}
  //       alt={imgData.title}
  //       onLoad={(e) => setImageStateSuccess(e)}
  //       onError={(e) => setImageStateError(e)}
  //     />
  //   );
  // };
  // const setImageStateSuccess = () => {
  //   setIsImageLoaded(true);
  //   setIsImageLoading(false);
  // };
  // const setImageStateError = () => {
  //   setIsImageLoaded(false);
  //   setIsImageLoading(false);
  // };
  const { moduleClass, imgData } = props;
  // return isImageLoading ? (
  //   <Shimmer rows={10} moduleClass="webcam" />
  // ) : isImageLoaded ? (
  return (
    <div className={`module__container ${moduleClass}`}>
      <ModuleHeader title={imgData.title} />
      <div className="module__body">
        <div className="webcam">
          <Image
            src={imgData.url}
            fallback={<Shimmer width={300} height={100} />}
            // fallback={<Breathing width={800} height={600} />}
            fadeIn={true}
            errorFallback={()=>(<div>Image is not available.</div>)}
          />
          
        </div>
      </div>
    </div>
  );
}

export default WebCam;
