import React, { Component } from "react";
import ModuleHeader from "./ModuleHeader";
function Controls (props) {

    const { clickFunction, title, controlClassName, checked } = props;
    return (
      <div className="switchContainer">
        <p>{title}</p>
        <div className={`switch ${controlClassName}`}>
          <input type="checkbox" onChange={(e)=>clickFunction(checked)} checked={checked}/>
          <label></label>
        </div> 
      </div>
    );
  }
export default Controls;


